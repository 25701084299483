export const customLink = () => {
  // Получаем ссылку по ее id или классу (в данном случае используем класс .custom-link)
  const linkElement = document.querySelector(".hero-form__btn");

  // Проверяем, что элемент существует и ширина экрана меньше 500 пикселей
  if (linkElement && window.innerWidth < 992) {
    // Устанавливаем новое значение атрибута href
    linkElement.href =
      "https://www.booking.com/hotel/ua/kambodzha-na-dynae.ru.html#roomstable_nodates";
  } else {
    console.error(
      "Элемент не найден или ширина экрана больше или равна 500 пикселям."
    );
    return;
  }
};

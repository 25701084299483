export const up = () => {
  window.addEventListener("scroll", () => {
    let scroll = document.querySelector(".up");
    scroll.classList.toggle("active", window.scrollY > 500);

    scroll.addEventListener("click", () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  });
};

import datepicker from "js-datepicker";

export const calendar = () => {
  const calendarSelector = document.querySelector(".calendar");

  if (!calendarSelector) {
    return;
  }

  datepicker(calendarSelector, {
    customDays: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Сб"],
    customMonths: [
      "Январь",
      "Февраль",
      "Март",
      "Апрель",
      "Май",
      "Июнь",
      "Июль",
      "Август",
      "Сентябрь",
      "Октябрь",
      "Ноябрь",
      "Декабрь",
    ],
    formatter: (input, date, instance) => {
      const value = date.toLocaleDateString();
      input.value = value; // => '1/1/2099'
    },
  });

  const clearButton = document.getElementById("clearButton");
  const inputElement = document.getElementById("myInput");
  const datepickerContainer = document.querySelector(
    ".qs-datepicker-container"
  );
  datepickerContainer.appendChild(clearButton);
  // Очистите содержимое поля ввода
  inputElement.value = "";

  clearButton.addEventListener("click", (e) => {
    e.preventDefault();

    const inputElement = document.getElementById("myInput");
    inputElement.value = "";
  });
};

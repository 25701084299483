import Swiper from "swiper";

export const swiper = () => {
  const check = document.querySelector(".swiper");

  if (!check) {
    return;
  }

  const customSwiper = new Swiper(".swiper", {
    loop: true,
    spaceBetween: 100,

    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 40,
      },

      1170: {
        slidesPerView: 2,
      },
      // when window width is >= 480px
      // 480: {
      //   slidesPerView: 3,
      //   spaceBetween: 30
      // },
      // // when window width is >= 640px
      // 640: {
      //   slidesPerView: 4,
      //   spaceBetween: 40
      // }
    },
  });

  document.getElementById("next-arrow").addEventListener("click", function () {
    customSwiper.slideNext();
  });

  // Функция для обновления высоты слайдов
  function updateSlideHeights() {
    var swiperSlides = document.querySelectorAll(".swiper-slide");
    var maxHeight = 0;

    swiperSlides.forEach(function (slide) {
      slide.style.height = ""; // Сбросить высоту на каждом слайде перед измерением новой высоты
      var slideHeight = slide.clientHeight;
      if (slideHeight > maxHeight) {
        maxHeight = slideHeight;
      }
    });

    swiperSlides.forEach(function (slide) {
      slide.style.height = maxHeight + "px";
    });
  }

  // Вызовите функцию при загрузке страницы и при изменении размера окна
  window.addEventListener("load", updateSlideHeights);
  window.addEventListener("resize", updateSlideHeights);
};

"use strict";

// Connecting vendors (plugins)
import "./_vendor";

// Functions
import {
  mobileCheck,
  burger,
  calendar,
  swiper,
  up,
  customLink,
} from "./functions/";

// Components
// import { formValidation } from "./components/";

window.addEventListener("DOMContentLoaded", () => {
  mobileCheck();
  burger();
  calendar();
  swiper();
  up();
  customLink();
});
